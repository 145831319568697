import {formCover} from "../assets/index";
import {useEffect, useState} from "react";
import * as emailjs from "emailjs-com";

const initialData = {
    name: '',
    email: '',
    message: ''
};

function Form() {
    const [formData, setFormData] = useState({...initialData});
    const [filled, setFilled] = useState(false);
    let loading = false;

    function areObjectValuesNotEmpty(obj) {
        return Object.values(obj).every(val => !!val);
    }

    useEffect(() => {
      if (areObjectValuesNotEmpty(formData)) {
          setFilled(true);
          return;
      }
        setFilled(false);
    }, [{...formData}])
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;

        loading = !loading;


        emailjs.sendForm('service_ndg00dr', 'template_5zme3n3', form, 'Sd2V01kahexPlDDXG')
            .then((result) => {
                setFormData({...initialData});
                loading = !loading;
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <section className='flex'>
            <div className='object-center hidden md:block md:w-1/3'><img className='w-full' src={formCover} alt="Solomiya"/></div>
            <div className='w-full md:w-2/3 flex flex-col px-6 md:px-16 py-14 md:pb-0 flex-grow'>
                <h3 className='text-xl font-medium'>ВИ МОЖЕТЕ ЗАПИСАТИСЬ НА ПЕРВИННУ КОНСУЛЬТАЦІЮ ДО <span className='font-semibold'>ОПРИШКО СОЛОМІЇ</span></h3>
                <h2 className='text-3xl md:text-5xl font-semibold mt-8'>ОНЛАЙН-ЗАПИС :</h2>
                <form className='flex flex-col gap-4 py-2 mt-8' onSubmit={handleSubmit}>
                    <label htmlFor="name">Імʼя</label>
                    <input value={formData.name} name='name' onChange={e => setFormData({...formData, name: e.target.value})} className='border rounded-xl px-4 py-2 bg-main-100 outline-none' placeholder='Вкажіть Ваше імʼя' type="text"/>

                    <label htmlFor="email">Електронна адреса</label>
                    <input value={formData.email} name='email' onChange={e => setFormData({...formData, email: e.target.value})} className='border rounded-xl px-4 py-2 bg-main-100 outline-none' placeholder='Вкажіть Ваш email' type="email"/>

                    <label htmlFor="message">Ваше питання</label>
                    <textarea value={formData.message} onChange={e => setFormData({...formData, message: e.target.value})} className='px-6 py-4 max-h-36 bg-main-100 outline-none rounded-2xl' name="message" cols="30" rows="10" placeholder='Опишіть Ваше питання'></textarea>
                    <input type='submit' disabled={!!loading || !filled} value="Записатись" className='md:max-w-[350px] bg-main-300 h-96 rounded-2xl text-main-200 py-4 px-2 mt-3'/>
                </form>
            </div>
        </section>
    );
}

export default Form;