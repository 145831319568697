import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {service1, service2, service3, service4, servicesBg} from '../assets/index'
import ServiceCard from "./ServiceCard";

const cards = [
    {title: 'Первинна консультація репродуктолога', img: service1},
    {title: 'УЗД органів малого тазу', img: service2},
    {title: 'Перевірка прохідності маткових труб', img: service3},
    {title: 'Діагностична гістероскопія', img: service4}
]
const responsive = {
    ultraWide: {
        breakpoint: { max: 3000, min: 1920 },
        items: 4,
        slidesToSlide: 1
    },
    desktop: {
        breakpoint: { max: 1920, min: 1024 },
        items: 3.2,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1110, min: 810 },
        items: 2.2,
        slidesToSlide: 1 // optional, default to 1.
    },
    miniTablet: {
        breakpoint: { max: 810, min: 531 },
        items: 1.2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 531, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};
function Services() {
    return (
        // <section className='flex flex-col min-w-[600px] pl-40 pt-12 pb-24 gap-14 bg-main-100 '>
        //     <h2 className='font-semibold text-5xl tracking-wider'>ПОСЛУГИ</h2>
        //     <Carousel responsive={responsive}>
        //         {cards.map(({title, img}, i) => (
        //            <ServiceCard key={i} title={title} img={img} carouselL={cards.length} i={i} />
        //         ))}
        //     </Carousel>
        //     {/*<div className='flex overflow-x-auto'>*/}
        //     {/*</div>*/}
        // </section>
        <section className="relative h-[660px] bg-gradient md:bg-none">
            <div className="md:bg-main-100 md:w-3/5 h-[100%] md:absolute left-0 md:pl-40 pt-14 pb-24">
                    <h1 className="font-semibold text-5xl text-center md:text-left tracking-wider">ПОСЛУГИ</h1>
            </div>
            <div className="bg-no-repeat bg-left bg-cover bg-services-bg opacity-50 md:w-2/5 h-full absolute right-0 h-[100%]">
            </div>
            <div className="md:px-14 xs:pr-0 xs:pl-40 w-screen absolute top-[25%]">
                <Carousel responsive={responsive} className=''>
                    {cards.map(({title, img}, i) => (
                        <ServiceCard key={i} title={title} img={img} carouselL={cards.length} i={i} />
                    ))}
                </Carousel>
            </div>
        </section>
    );
}

export default Services;