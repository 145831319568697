function ServiceCard({title, img, i, carouselL}) {
    return (
        <div className='mx-14 flex flex-col bg-main-200 h-[380px] w-[280px]'>
            <img className={`h-[300px] ${i === carouselL - 1 ? `object-top` : `object-center`} object-cover`} src={img} alt="/"/>
            <div className='flex grow items-center justify-center text-center'>
                <h4 className='font-semibold px-4'>{title}</h4>
            </div>
        </div>
    );
}

export default ServiceCard;