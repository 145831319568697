import {nadijaLogo, overviewCover, arrowRight} from '../assets/index'

function Overview() {
    return (
        <section className='overview'>
            <div className='md:bg-overview-bg bg-no-repeat md:min-h-[800px] bg-center flex flex-wrap'>
                <div className='hidden px-40 py-12 md:flex flex-col w-1 md:w-3/5 gap-96'>
                    <img className='h-44 w-40' src={nadijaLogo} alt="Лого Клініки Надія"/>
                    <a target='_blank' href='https://ivfclinic.lviv.ua' className='py-6 max-h-40 max-w-md bg-main-300 rounded-3xl text-main-200 text-center font-semibold'>ПОСИЛАННЯ НА САЙТ КЛІНІКИ <img
                        className='inline-block ml-3 h-7' src={arrowRight} alt="стрілка направо"/></a>
                </div>
                <article className='bg-main-100 md:bg-main-200 md:w-2/5 md:opacity-80'>
                    <div className='md:px-8 px-6 md:pr-28 py-14 md:py-20'>
                        <h2 className='font-medium text-5xl md:text-6xl'>Лікування непліддя</h2>
                        <h4 className='text-2xl pt-10 md:pt-28'>КЛІНІКА РЕПРОДУКТИВНОЇ МЕДИЦИНИ "Надія"</h4>
                        <p className='py-10 font-medium'>Лідер у галузі репродуктивної медицини в Україні, об’єднує команду вчених і клініцистів, які прагнуть забезпечити найкраще лікування безпліддя — від підтримки найвищих медичних і наукових стандартів
                        до підтримки на кожному етапі вашої вагітності та подорожі до батьківства</p>
                        <a target='_blank' href='https://ivfclinic.lviv.ua' className='md:hidden block py-6 px-6 max-h-40 max-w-md bg-main-300 rounded-3xl text-main-200 text-center font-semibold'>ПЕРЕЙТИ НА САЙТ КЛІНІКИ <img
                            className='inline-block ml-3 h-7' src={arrowRight} alt="стрілка направо"/></a>
                    </div>
                </article>
            </div>
        </section>
    );
}

export default Overview;