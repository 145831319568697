import Intro from "./components/Intro";
import Overview from "./components/Overview";
import Services from "./components/Services";
import Form from "./components/Form";

function App() {
    return (
        <main className='font-base'>
            <Intro/>
            <Overview/>
            <Services/>
            <Form/>
        </main>
    );
}

export default App;
