import {cover, nadijaLogo, instagramLogo, facebookLogo, telegramLogo} from '../assets/index'

const logos = [
    {name: 'Лого Фейсбук', img: facebookLogo, link: 'https://www.facebook.com/solomiya.opryshko'},
    {name: 'Лого Інстаграм', img: instagramLogo, link: 'https://www.instagram.com/solomia.opryshko/'},
    {name: 'Лого Телеграм', img: telegramLogo, link: 'https://t.me/solomiyka_lviv'}
]
function Intro() {
    const tel = '+380 (073) 075-7085';
    const scrollToBottom = () => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }

    return (
        // <section className='h-custom flex md:flex-row flex-col'>
        <section className='flex md:flex-row flex-col'>
            <div className='bg-main-100 flex flex-col items-center py-14 md:p-8 px-6 gap-7 w-full md:w-3/5'>
                <img className='rounded-[50%] max-h-80' src={cover} alt="Опришко Соломія"/>
                <h4 className='text-xl tracking-widest font-medium'>Socials</h4>
                <div>
                    <nav>
                        <ul className='flex gap-10'>
                            {logos.map((logo, i )=> (
                                <li key={i} className="social">
                                    <a target='_blank' href={logo.link}>
                                        <img className='min-h-[36px]' src={logo.img} alt={logo.name}/>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                <img className='mt-4 max-h-20' src={nadijaLogo} alt="Логотип клініки Надія"/>
                <div className='text-main-300 font-medium text-xl text-center'>вул. Залізнична, 7 (3-й поверх) </div>
            </div>
            <div className='w-full md:w-2/5 bg-main-200 pt-16 pb-20 md:py-8 md:pt-32 px-6 md:px-14 flex flex-col gap-7 md:gap-10'>
                <div className='text-5xl xl:text-7xl font-medium tracking-tight'>Опришко Соломія</div>
                <div className='font-semibold tracking-widest md:pr-5'>АКУШЕР - ГІНЕКОЛОГ,<br className='hidden md:block'/>
                    ЛІКАР РЕПРОДУКТОЛОГ</div>
                <div className='flex flex-col max-h-auto md:w-80 mt-9 text-center gap-4'>
                    <button onClick={scrollToBottom} className='py-6 md:px-10 md:py-2 px-2 max-h-32 bg-main-300 rounded-3xl text-main-200 font-semibold'>ЗАПИСАТИСЬ НА КОНСУЛЬТАЦІЮ</button>
                    <div className='font-extralight text-xl md:text-sm'>або<br/>зателефонуйте за номером</div>
                    <a href={`tel:${tel}`} className='font-semibold text-3xl md:text-base text-black'>{tel}</a>
                </div>
            </div>
        </section>
    );
}

export default Intro;